// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-completed-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/admin/completed.js" /* webpackChunkName: "component---src-pages-admin-completed-js" */),
  "component---src-pages-admin-index-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-inprocess-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/admin/inprocess.js" /* webpackChunkName: "component---src-pages-admin-inprocess-js" */),
  "component---src-pages-admin-stats-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/admin/stats.js" /* webpackChunkName: "component---src-pages-admin-stats-js" */),
  "component---src-pages-admin-unmatched-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/admin/unmatched.js" /* webpackChunkName: "component---src-pages-admin-unmatched-js" */),
  "component---src-pages-index-js": () => import("/go/src/github.com/src/7k/frontend/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

